import http from "./http";

const dealerApi = "/dealer";

export const addDealer = (data) => {
  return http.post(`${dealerApi}/add`, data);
};

export const getAllDealers = (data) => {
  return http.get(`${dealerApi}/get`, data);
};

export const deleteDealer = (dealerId) => {
  if (!dealerId) {
    return alert("dealerId is missing");
  }
  return http.delete(`${dealerApi}/delete/${dealerId}`);
};
