import http from "./http";

const companyDetailsApi = "/companyDetails";

export const addCompanyDetails = (data) => {
  return http.post(`${companyDetailsApi}/add`, data);
};

export const getCompanyDetails = (data) => {
  return http.get(`${companyDetailsApi}/get`, data);
};
