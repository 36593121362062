import { Button } from "@chakra-ui/react";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { FaEdit, FaPlus, FaTrash } from "react-icons/fa";
import { toast } from "sonner";
import { getErrorMessage } from "../../../functions";
import {
  createFactory,
  deleteFactory,
  getFactories,
  updateFactory,
} from "../../../services/v2/factory.api";
import { GapV2 } from "../../../UI/Gap";
import { InputFieldV2 } from "../../../UI/InputField";
import DeleteConfirmationButton from "../../../UI/v2/DeleteConfirmationButton";

const Factory = ({ initialValues, validationSchema, fields }) => {
  const [factories, setFactories] = useState([]);
  const [editingFactory, setEditingFactory] = useState(null);
  const searchParams = new URLSearchParams(window.location.search);
  const component = searchParams.get("component");

  // Fetch factories on component mount
  useEffect(() => {
    getFactories(`?where=${JSON.stringify({ type: component })}`).then(
      (res) => {
        setFactories(res.data);
      }
    );
  }, []);

  // Formik configuration
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        if (editingFactory) {
          // Update the factory
          const updatedFactory = await updateFactory(editingFactory._id, {
            data: values,
          });
          setFactories((prevFactories) =>
            prevFactories.map((factory) =>
              factory._id === editingFactory._id
                ? { ...factory, data: updatedFactory.data.data }
                : factory
            )
          );
          setEditingFactory(null); // Exit edit mode
        } else {
          // Add a new factory
          const newFactory = await createFactory({
            data: values,
            type: component,
          });
          setFactories([...factories, newFactory.data]);
        }

        formik.resetForm();
      } catch (error) {
        toast.error(getErrorMessage(error));
      }
    },
  });

  // Handle edit
  const handleEdit = (factory) => {
    setEditingFactory(factory);
    formik.setValues(factory.data || {}); // Set all fields for editing
  };

  // Handle delete
  const handleDelete = async (id) => {
    try {
      await deleteFactory(id);
      setFactories((prevFactories) =>
        prevFactories.filter((factory) => factory._id !== id)
      );
    } catch (error) {
      toast.error(getErrorMessage(error));
    }
  };

  return (
    <div className="p-4 space-y-6">
      {/* Form Section */}
      <GapV2 header={editingFactory ? `Edit ${component}` : `New ${component}`}>
        <form
          onSubmit={formik.handleSubmit}
          className="space-y-4 bg-gray-50 p-4 rounded-lg shadow-md"
        >
          {fields?.map((field, index) => (
            <InputFieldV2
              key={index}
              labelName={field.labelName}
              type={field.type}
              required={field.required}
              name={field.name}
              value={formik.values[field.name] || ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              inputClass={"rounded-lg text-gray-800"}
              error={formik.touched[field.name] && formik.errors[field.name]}
              touched={formik.touched[field.name]}
            />
          ))}
          <button
            type="submit"
            className={`${
              editingFactory
                ? "bg-yellow-500 hover:bg-yellow-600"
                : "bg-blue-500 hover:bg-blue-600"
            } text-white px-4 py-2 rounded-lg mt-2 w-full flex gap-2 items-center justify-center`}
          >
            <span>{editingFactory ? "Update" : "Add"}</span>
            <FaPlus />
          </button>
        </form>
      </GapV2>

      {/* Existing Factories Section */}
      <GapV2 header={`Existing ${component}`}>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {factories.map((factory) => (
            <div
              key={factory._id}
              className="bg-white p-4 shadow-md flex flex-col justify-between rounded-lg border border-gray-200"
            >
              <div className="space-y-2">
                {fields.map((field, idx) => (
                  <div key={idx} className="text-sm">
                    <span className="font-semibold">{field.labelName}: </span>
                    <span>{factory?.data?.[field.name]}</span>
                  </div>
                ))}
              </div>
              <div className="flex justify-end gap-2 mt-4">
                {/* Edit Button */}
                <Button
                  onClick={() => handleEdit(factory)}
                  variant={"solid"}
                  backgroundColor={"blue.500"}
                  color={"white"}
                  _hover={{
                    backgroundColor: "blue.600",
                  }}
                  gap={2}
                  width={"full"}
                >
                  <FaEdit />
                  <span>Edit</span>
                </Button>

                {/* Delete Button */}
                <DeleteConfirmationButton
                  onConfirm={() => handleDelete(factory._id)}
                  buttonText="Delete"
                  confirmText="This action cannot be undone."
                  confirmTitle="Are you sure?"
                  icon={<FaTrash />}
                />
              </div>
            </div>
          ))}
        </div>
      </GapV2>
    </div>
  );
};

export default Factory;

