import http from "./http";

const batteryDetailsApi = "/battery/details";

export const addBatteryDetails = (data) => {
  return http.post(`${batteryDetailsApi}/add`, data);
};

export const getAllBatteryDetails = (query) => {
  return http.get(`${batteryDetailsApi}/getAll${query}`);
};

export const deleteBatteryDetails = (dateOfEntry) => {
  if (!dateOfEntry) {
    return alert("dateOfEntry is missing");
  }
  return http.delete(`${batteryDetailsApi}/delete/${dateOfEntry}`);
};
