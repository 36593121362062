import http from "./http";

const qrDataApi = "/qr/data";

export const addQrData = (data) => {
  return http.post(`${qrDataApi}/add`, data);
};
export const createBulk = (data) => {
  return http.post(`${qrDataApi}/bulk`, data);
};
export const bulkDownload = (data) => {
  return http.post(`${qrDataApi}/download/bulk`, data);
};

export const getQrData = (data) => {
  return http.get(`${qrDataApi}/get`, data);
};

export const deleteQrData = (qrDataId) => {
  if (!qrDataId) {
    return alert("qrDataId is missing");
  }
  return http.delete(`${qrDataApi}/delete/${qrDataId}`);
};

export const updateQrDetails = (qrDataId, qrDetails) => {
  if (!qrDataId) {
    return alert("qrDataId is missing");
  }
  return http.put(`${qrDataApi}/update/${qrDataId}`, qrDetails);
};

export const downloadQR = (data) => {
  return http.post(`${qrDataApi}/download`, data);
};

export const getQrDetailById = (qrDataId) => {
  return http.get(`${qrDataApi}/details/${qrDataId}`);
};
