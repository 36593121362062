import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Heading,
  HStack,
  Input,
  Stack,
} from "@chakra-ui/react";
import { Form, Formik, Field } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import * as Yup from "yup";
import { getErrorMessage, getSuccessMessage } from "../../functions";
import imageConstant from "../../imageConstant";
import { login as loginReducer } from "../../redux/authSlice";
import { login } from "../../services/userApi";
import Loader from "../../UI/Loader";
import { Logo } from "./Logo";

export const Login = () => {
  const navigate = useNavigate();
  const { auth } = useSelector((state) => state);
  const { isAuth } = auth;
  if (isAuth) window.location.href = "/dashboard";
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const handleLogin = async (values, { setSubmitting }) => {
    try {
      setLoading(true);
      const res = await login(values);
      toast.success(getSuccessMessage(res));
      dispatch(
        loginReducer({
          isAuth: true,
          isAdmin: res.data.isAdmin,
          token: res.data.token,
          user: res.data.user,
          classes: res.data.classes,
        })
      );
      navigate("/dashboard");
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
      setSubmitting(false);
    }
  };

  if (loading) return <Loader />;

  return (
    <div className="flex">
      <div className="w-1/2 h-screen hidden lg:flex flex-col justify-center items-center text-center">
        <img
          src={imageConstant.signup}
          className="w-1/2 p-10 object-cover fixed"
          alt="login"
        />
      </div>

      <Container className="mt-20">
        <Stack spacing="2">
          <Stack spacing="2">
            <div className="flex justify-center">
              <Logo />
            </div>
            <Stack
              spacing={{
                base: "2",
                md: "3",
              }}
              textAlign="center"
            >
              <Heading
                size={{
                  base: "xs",
                  md: "sm",
                }}
              >
                Log in to your account
              </Heading>
            </Stack>
          </Stack>
          <Box
            py={{
              base: "0",
              sm: "8",
            }}
            px={{
              base: "4",
              sm: "10",
            }}
            bg={{
              base: "transparent",
              sm: "bg-surface",
            }}
            boxShadow={{
              base: "none",
              sm: "md",
            }}
            borderRadius={{
              base: "none",
              sm: "xl",
            }}
          >
            <Formik
              initialValues={{ email: "", password: "" }}
              validationSchema={validationSchema}
              onSubmit={handleLogin}
            >
              {({ isSubmitting }) => (
                <Form>
                  <Stack spacing="6">
                    <Field name="email">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.email && form.touched.email}
                        >
                          <FormLabel htmlFor="email">Email</FormLabel>
                          <Input {...field} id="email" type="email" />
                          <FormErrorMessage>
                            {form.errors.email}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="password">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.password && form.touched.password
                          }
                        >
                          <FormLabel htmlFor="password">Password</FormLabel>
                          <Input {...field} id="password" type="password" />
                          <FormErrorMessage>
                            {form.errors.password}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <HStack justify="space-between" className="flex flex-wrap">
                      <Button
                        rounded={"none"}
                        variant="link"
                        colorScheme="blue"
                        size="sm"
                        onClick={() => navigate("/signup")}
                      >
                        Don&apos;t have an account? Sign up
                      </Button>
                      <Button
                        rounded={"none"}
                        variant="link"
                        colorScheme="blue"
                        size="sm"
                        onClick={() => navigate("/forgot-password")}
                      >
                        Forgot Password?
                      </Button>
                    </HStack>
                    <Stack spacing="6">
                      <Button
                        rounded={"none"}
                        type="submit"
                        variant="primary"
                        className="bg-primary text-white"
                        isLoading={isSubmitting}
                      >
                        Sign in
                      </Button>
                    </Stack>
                  </Stack>
                </Form>
              )}
            </Formik>
          </Box>
        </Stack>
      </Container>
    </div>
  );
};

export default Login;
