import { Button, Image } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FaDownload } from "react-icons/fa";
import { toast } from "sonner";
import { Logo } from "../components/Login/Logo";
import {
  getErrorMessage,
  invoicePDFTemplate
} from "../functions";
import imageConstant from "../imageConstant";
import { downloadPdf, getInvoiceById } from "../services/invoiceApi";
import Loader from "../UI/Loader";

const Invoice = () => {
  const [invoiceId, setInvoiceId] = useState("");
  const [invoice, setInvoice] = useState({});
  const [loading, setLoading] = useState(false);
  const [copyType, setCopyType] = useState("");
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("invoiceId");
    const copyType = urlParams.get("copyType");
    if (id) {
      setInvoiceId(id);
    }
    if (copyType) {
      setCopyType(copyType);
    }
  }, []);

  const fetchInvoiceById = async () => {
    try {
      setLoading(true);
      if (invoiceId) {
        const res = await getInvoiceById(`?invoiceId=${invoiceId}`);
        setInvoice(res.data);
      }
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchInvoiceById();
  }, [invoiceId]);
  const handleDownloadPdf = async (invoice) => {
    try {
      if (!copyType) {
        return toast.error("Please select a copy type");
      }
      if (!invoice.invoiceNumber) {
        return toast.error("Invoice not found");
      }
      setLoading(true);
      // Assuming your backend endpoint is something like '/download'
      const response = await downloadPdf({
        // html: invoice.isTaxIncluded
        //   ? newInvoicePDFTemplate(invoice, copyType)
        //   : invoicePDFTemplate(invoice, copyType),
        html: invoicePDFTemplate(invoice, copyType),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // Create a blob from the response
      const blob = await response.blob();

      // Create a temporary anchor element to trigger the download
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "invoice.pdf"); // or any other filename you prefer
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <Loader />;

  return (
    <div className="lg:p-10 flex items-center mt-10 lg:justify-start h-full flex-col gap-10">
      <Logo />
      <Image src={imageConstant.invoice} className="lg:w-1/3 h-1/3 pb-10" />
      <Button
rounded={"none"}
        size={"lg"}
        colorScheme="blue"
        className="flex items-center gap-2 justify-center "
        onClick={() => handleDownloadPdf(invoice)}
      >
        <h1 className="text-2xl">Download PDF</h1>
        <FaDownload size={20} />
      </Button>

      <div className="flex flex-col justify-center items-center gap-4">
        <h1>Generate your QR or GST QrDetail for free </h1>
        <a
          href={`${process.env.REACT_APP_FRONTEND_URL}`}
          className="text-blue-500 underline italic"
        >
          Click here
        </a>
      </div>
    </div>
  );
};

export default Invoice;
