import React, { useState } from "react";
import { FaEdit, FaQrcode, FaTrashAlt } from "react-icons/fa";
import { Button, Table, Tbody, Td, Tr } from "@chakra-ui/react";
import { toast } from "sonner";
import Loader from "../../../UI/Loader";
export default function QrCardTable({
  data,
  deleteQrData,
  handleSelectQR,
  itemId,
  isSelected,
}) {
  const [loading, setLoading] = useState(false);
  const downloadQRCode = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/qr/data/download`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            qrText: `${process.env.REACT_APP_FRONTEND_URL}/qr/?qrId=${itemId}`,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to download QR code: ${response.statusText}`);
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "qr-code.png";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);

      toast.success("QR code downloaded successfully!");
    } catch (error) {
      toast.error(`Failed to download QR code: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <Loader />;
  return (
    <div className="overflow-x-auto">
      <Table variant={isSelected ? "simple" : "striped"}>
        <Tbody className={isSelected ? "bg-blue-200" : ""}>
          {data.map((d) => (
            <Tr key={d._id}>
              <Td className="font-bold">{d.labelName}</Td>
              <Td>{d.value}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <div className="w-full flex lg:flex-row flex-col justify-between gap-2 p-2">
        <Button
rounded={"none"}
          className="w-full  flex gap-2"
          colorScheme="blue"
          onClick={handleSelectQR}
        >
          <span>Edit</span> <FaEdit size={20} />
        </Button>
        <Button
rounded={"none"}
          className="w-full  flex gap-2"
          colorScheme="twitter"
          onClick={downloadQRCode}
        >
          <span>Download</span> <FaQrcode size={20} />
        </Button>
        <Button
rounded={"none"}
          className="w-full  flex gap-2"
          colorScheme="red"
          onClick={deleteQrData}
        >
          <span>Delete</span> <FaTrashAlt size={20} />
        </Button>
      </div>
    </div>
  );
}
