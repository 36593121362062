import React, { useEffect, useRef, useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const Gap = (props) => {
  const className = props.className === undefined ? "" : props.className;
  return (
    <div
      className={`flex-none flex items-center justify-center text-white font-semibold ${className} my-4`}
    >
      <div className=" bg-primary px-6 py-1.5 w-64 text-center text-white">
        {props.children}
      </div>
      <div className="w-[100%] bg-primary h-1"></div>
    </div>
  );
};
export default Gap;

export const GapV2 = ({ className = "", children, header }) => {
  const [isOpen, setIsOpen] = useState(true); // State to manage open/close
  const [contentHeight, setContentHeight] = useState(0); // State to manage content height
  const contentRef = useRef(null); // Ref for the content div

  const toggleOpen = () => setIsOpen(!isOpen);

  // Recalculate content height when `children` or `isOpen` changes
  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(isOpen ? contentRef.current.scrollHeight : 0);
    }
  }, [children, isOpen]);

  return (
    <div
      className={`flex-none flex flex-col items-center justify-center text-white font-semibold ${className} my-4 rounded-lg`}
    >
      {/* Header Section */}
      <div
        className="bg-primary px-6 py-1.5 w-11/12 flex justify-between items-center cursor-pointer rounded-lg"
        onClick={toggleOpen}
      >
        <span>{header || "Click to Toggle"}</span>
        {isOpen ? (
          <FaChevronUp className="w-5 h-5 text-white" />
        ) : (
          <FaChevronDown className="w-5 h-5 text-white" />
        )}
      </div>
      {/* Collapsible Content */}
      <div
        className={`w-full overflow-hidden transition-[max-height] duration-300 ease-in-out`}
        style={{
          maxHeight: `${contentHeight}px`, // Dynamically set height
        }}
      >
        <div ref={contentRef} className="p-4">
          {children}
        </div>
      </div>
    </div>
  );
};
