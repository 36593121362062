import {
    Button,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
} from "@chakra-ui/react";
import React, { useState } from "react";

const DeleteConfirmationButton = ({
  onConfirm,
  buttonText,
  confirmText,
  confirmTitle,
  icon,
  colorScheme,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const handleConfirm = () => {
    onConfirm();
    closeModal();
  };

  return (
    <>
      {/* Trigger Button */}
      <Button
        onClick={openModal}
        variant="solid"
        colorScheme={colorScheme || "red"}
        gap={2}
        width="full"
      >
        {icon}
        <span>{buttonText || "Action"}</span>
      </Button>

      {/* Confirmation Modal */}
      <Modal isOpen={isOpen} onClose={closeModal} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{confirmTitle || "Are you sure?"}</ModalHeader>
          <ModalBody>
            {confirmText || "This action cannot be undone."}
          </ModalBody>
          <ModalFooter>
            <Button onClick={closeModal} variant="ghost" mr={3}>
              Cancel
            </Button>
            <Button onClick={handleConfirm} colorScheme={colorScheme || "red"}>
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DeleteConfirmationButton;
