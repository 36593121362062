import http from "./http";

const qrSettingsApi = "/qr/settings";

export const addQrSettings = (data) => {
  return http.post(`${qrSettingsApi}/add`, data);
};

export const getQrSettings = (data) => {
  return http.get(`${qrSettingsApi}/get`, data);
};

export const deleteQrSettings = (qrSettingsId) => {
  if (!qrSettingsId) {
    return alert("qrSettingsId is missing");
  }
  return http.delete(`${qrSettingsApi}/delete/${qrSettingsId}`);
};
