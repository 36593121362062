import http from "../http";

export const getFactories = async (query) => {
  const res = await http.get(`/v2/factory${query}`);
  return res;
};

export const createFactory = async (data) => {
  const res = await http.post("/v2/factory", data);
  return res;
};

export const updateFactory = async (id, data) => {
  const res = await http.put(`/v2/factory/${id}`, data);
  return res;
};

export const deleteFactory = async (id) => {
  const res = await http.delete(`/v2/factory/${id}`);
  return res;
};
