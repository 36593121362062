import http from "./http";

const khatabookApi = "/khatabook";

export const getKhatabook = (query) => {
  return http.get(`${khatabookApi}/get`, query);
};

export const createKhata = (data) => {
  return http.post(`${khatabookApi}/create`, data);
};

export const updateKhatabook = (id, data) => {
  return http.put(`${khatabookApi}/update/${id}`, data);
};

export const getAllKhatabook = (query) => {
  return http.get(`${khatabookApi}/all`, query);
};

export const khataOfAEmployee = (query) => {
  return http.get(`${khatabookApi}/employee/all${query}`);
};

export const updateKhataOfAEmployee = (id, data) => {
  return http.put(`${khatabookApi}/employee/update/${id}`, data);
};

export const deleteKhataOfAEmployee = (id) => {
  return http.delete(`${khatabookApi}/employee/delete/${id}`);
};

export const startNextMonthKhataOfAEmployee = (query) => {
  return http.get(`${khatabookApi}/employee/startNextMonth${query}`);
};
