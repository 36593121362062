import http from "./http";

const customerApi = "/customer";

export const addCustomer = (data) => {
  return http.post(`${customerApi}/add`, data);
};

export const updateCustomer = (data, query) => {
  if (!query) {
    return alert("Can't update user right now");
  }
  return http.put(`${customerApi}/update${query}`, data);
};

export const deleteCustomer = (customerId) => {
  if (!customerId) {
    return alert("CustomerId is missing");
  }
  return http.delete(`${customerApi}/delete/${customerId}`);
};

export const getAllCustomers = (data) => {
  return http.get(`${customerApi}/get`, data);
};
