import http from "./http";

const employeeApi = "/auth/employee";

export const addEmployee = (data) => {
  return http.post(`${employeeApi}/add`, data);
};

export const getAllEmployees = (data) => {
  return http.get(`${employeeApi}/get`, data);
};

export const deleteEmployee = (employeeId) => {
  if (!employeeId) {
    return alert("employeeId is missing");
  }
  return http.delete(`${employeeApi}/delete/${employeeId}`);
};

export const updateEmployee = (employeeId, data) => {
  if (!employeeId) {
    return alert("employeeId is missing");
  }
  return http.put(`${employeeApi}/update/${employeeId}`, data);
};
