import React from "react";
import SelectField from "./SelectField";
import CreatableSelect from "react-select/creatable";

const InputField = ({
  labelName,
  name,
  type,
  uni,
  ref,
  placeholder,
  onChange,
  value,
  touched,
  min = 0,
  max,
  error,
  onBlur,
  disabled,
  rowWise,
  inputClass,
  options,
  required = false,
  selectStyles,
  checked = false,
}) => {
  return (
    <div>
      <div
        className={`col-span-1  focus:outline-none focus:border-blue-500 ${
          rowWise ? "grid grid-cols-3" : "flex flex-col"
        } `}
      >
        <label
          className=" text-secondary font-bold col-span-1 flex items-center"
          htmlFor={uni}
        >
          {labelName}
        </label>
        {type === "select" ? (
          <SelectField
            ref={ref}
            disabled={disabled}
            name={name}
            min="0"
            value={value}
            onChange={onChange}
            className={`border-[1px] p-2 bg-white focus:outline-none focus:border-blue-500   ${
              disabled ? "bg-gray-300" : ""
            } ${inputClass}`}
            placeholder={placeholder}
            type={type}
            id={uni}
            onBlur={onBlur}
            required={required}
            options={options}
            selectStyles={selectStyles}
          />
        ) : type === "textarea" ? (
          <textarea
            disabled={disabled}
            min="0"
            value={value}
            ref={ref}
            name={name}
            onChange={onChange}
            className={`border-[1px] p-2 focus:outline-none focus:border-blue-500  ${
              disabled
                ? "bg-gray-300 hover:cursor-not-allowed text-gray-800"
                : "bg-white"
            } ${inputClass}`}
            placeholder={placeholder}
            type={type}
            id={uni}
            onBlur={onBlur}
            required={required}
          />
        ) : (
          <input
            disabled={disabled}
            min={min}
            max={max}
            value={value}
            ref={ref}
            checked={checked}
            //allow decimal
            step={"any"}
            //disable stepper

            onChange={onChange}
            className={`border-[1px] p-1.5  no-stepper  focus:outline-none focus:border-blue-500 ${
              disabled
                ? "bg-gray-300 hover:cursor-not-allowed text-gray-800"
                : "bg-white"
            } ${inputClass}`}
            placeholder={placeholder}
            type={type}
            id={uni}
            name={uni}
            onBlur={onBlur}
            required={required}
          />
        )}
      </div>
      {touched && error && <p className="text-red-500 ">{error}</p>}
    </div>
  );
};

export default InputField;

export const InputFieldV2 = ({
  labelName,
  name,
  type = "text",
  placeholder = "",
  value,
  onChange,
  onBlur,
  required = false,
  inputClass = "",
  disabled = false,
  error = "",
  touched = false,
  options = [], // For select field
  rows = 3, // For textarea
  onCreateOption = () => {}, // For select field
}) => {
  const baseInputClasses =
    "border-[1px] p-2 rounded-md focus:outline-none focus:border-blue-500 w-full";
  const disabledClasses = "bg-gray-300 hover:cursor-not-allowed text-gray-800";
  const resolvedInputClasses = `${baseInputClasses} ${
    disabled ? disabledClasses : "bg-white"
  } ${inputClass}`;

  // Render input field based on type
  const renderField = () => {
    switch (type) {
      case "textarea":
        return (
          <textarea
            id={name}
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            disabled={disabled}
            rows={rows}
            className={resolvedInputClasses}
          />
        );
      case "select":
        return (
          <CreatableSelect
            isDisabled={disabled}
            isClearable
            name={name}
            value={options.find((opt) => opt.value === value) || null}
            onChange={(selected) =>
              onChange({
                target: { name, value: selected ? selected.value : "" },
              })
            }
            onCreateOption={onCreateOption}
            options={options}
            placeholder={placeholder || "Select or create an option"}
            classNamePrefix="react-select"
            className="text-gray-800 p-2 rounded-md focus:outline-none focus:border-blue-500 w-full"
          />
        );
      default:
        return (
          <input
            id={name}
            name={name}
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            disabled={disabled}
            className={resolvedInputClasses}
          />
        );
    }
  };

  return (
    <div className="mb-4">
      {/* Label */}
      <label htmlFor={name} className="block text-secondary font-medium mb-1">
        {labelName} {required && <span className="text-red-500">*</span>}
      </label>

      {/* Render Field */}
      {renderField()}

      {/* Error Message */}
      {touched && error && <p className="text-red-500 text-sm mt-1">{error}</p>}
    </div>
  );
};
