import http from "./http";

const inventoryApi = "/inventory";

export const addInventory = (data) => {
  return http.post(`${inventoryApi}/add`, data);
};

export const getAllInventory = () => {
  return http.get(`${inventoryApi}/get`);
};

export const deleteInventory = (dateOfEntry) => {
  if (!dateOfEntry) {
    return alert("dateOfEntry is missing");
  }
  return http.delete(`${inventoryApi}/delete/${dateOfEntry}`);
};
