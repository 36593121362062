import http from "./http";

const invoiceApi = "/invoice";

export const addInvoice = (data) => {
  return http.post(`${invoiceApi}/add`, data);
};

export const sendToMail = (data) => {
  return http.post(`${invoiceApi}/send/mail`, data);
};
export const downloadPdf = async (data) => {
  const token = localStorage.getItem("token");
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}${invoiceApi}/pdf/download`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    }
  );
  return response;
};

export const getAllInvoice = (data) => {
  return http.get(`${invoiceApi}/get`, data);
};

export const getInvoiceById = (query = "") => {
  return http.get(`${invoiceApi}/invoice${query}`);
};

export const deleteInvoice = (invoiceId) => {
  if (!invoiceId) {
    return alert("invoiceId is missing");
  }
  return http.delete(`${invoiceApi}/delete/${invoiceId}`);
};
