import http from "./http";

const cashflowApi = "/cashflow";

export const addCashFlow = (data) => {
  return http.post(`${cashflowApi}/add`, data);
};

export const getAllCashFlow = () => {
  return http.get(`${cashflowApi}/get`);
};

export const deleteCashFlow = (dateOfEntry) => {
  if (!dateOfEntry) {
    return alert("dateOfEntry is missing");
  }
  return http.delete(`${cashflowApi}/delete/${dateOfEntry}`);
};
